import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { JSend } from '../../models/jsend.model';

export type Entities = 'contracts' | 'fixations' | 'liquidations' | 'cpe' | 'applications' | 'slots' | 'syngenta_contracts';

export interface EntityImport {
  key: Entities,
  label: string,
  base_file_url: string,
}

@Injectable({
  providedIn: 'root'
})
export class CsvImporterService {

  private baseUrl: string = '/:apiImporter/';
  private importerUrl: string = this.baseUrl + ':companyId/entity/:entityTag';

  constructor(
    private http: HttpClient
  ) { }

  public fromFileManager(companyId: number, userId: number, entity: Entities, sources: string[]): Observable<JSend<{ data: any; message: string; }>> {
    const url = this.importerUrl
      .replace(":companyId", companyId.toString())
      .replace(':entityTag', entity);

    const headers = new HttpHeaders({
      // 'Content-Type': file.type,
      'company_id': String(companyId),
      'user_id': String(userId)
    });

    return this.http.post<JSend<{ data: any, message: string }>>(url, { sources }, { headers });
  }
}
